import { useState } from "react";

// intended for use with PromotionalContentModal component
const usePromotionalContentModal = () => {
  const [showContent, setShowContent] = useState<boolean>(false);
  const [promotionalID, setPromotionalID] = useState<string>("");

  const openContent = (val: string) => {
    setShowContent(true);
    setPromotionalID(val);
  };

  return {
    showContent,
    setShowContent,
    promotionalID,
    setPromotionalID,
    openContent,
  };
};

export default usePromotionalContentModal;
