import { Box, CircularProgress, Dialog, DialogContent } from "@mui/material";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import parse, { domToReact, Element } from "html-react-parser";
import CloseIcon from "@mui/icons-material/Close";
import { FlexBox } from "components/flex-box";
import modules from "modules";
import { useAppSelector } from "hooks/use-app-selector";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useRouter } from "next/router";

interface Props {
  uuid: string;
  open: boolean;
  onClose: () => void;
}

const { selectors: promotionalSelectors, actions: promotionalActions } =
  modules.promotional;

export const PromotionalContentModal: FC<Props> = ({
  uuid = "",
  open,
  onClose,
}) => {
  // TODO: move content modal hook to hooks/promotional folder
  const dispatch = useAppDispatch();
  const { push } = useRouter();

  const content = useAppSelector(promotionalSelectors.selectContent);
  const id = useAppSelector(promotionalSelectors.selectUUID);
  const slug = useAppSelector(promotionalSelectors.selectSlug);
  const isLoading = useAppSelector(promotionalSelectors.selectContentLoading);

  const handleNavigate = useCallback(() => {
    const url = `/promotional/details/${id}?promo=${slug}`;
    push(url);
  }, [id, push, slug]);

  // Wrap the banner image with clickable link to promo details
  const handleContent = useCallback(
    (val: string) => {
      let imgWrapped = false;
      return parse(val, {
        replace(domNode) {
          // coded this way to avoid ts error
          if (domNode.type === "tag") {
            const element = domNode as Element;
            if (element.name === "img") {
              imgWrapped = true; // Ensure we only wrap the first image
              const imgElement = domToReact([domNode]); // Convert img element back to React
              return (
                <a onClick={handleNavigate} style={{ cursor: "pointer" }}>
                  {imgElement}
                </a>
              );
            }
          }
        },
      });
    },
    [handleNavigate]
  );

  // only set content if dialog recieves valid uuid
  const conditionalContent = useMemo(() => {
    return uuid && content ? handleContent(content) : "";
  }, [content, handleContent, uuid]);

  const handleGetContent = useCallback(() => {
    if (uuid) {
      dispatch(promotionalActions.getPromotionalContentThunk({ id: uuid }));
    }
  }, [dispatch, uuid]);

  useEffect(() => {
    handleGetContent();
  }, [handleGetContent]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{ border: "1px solid black" }}>
        <FlexBox sx={{ justifyContent: "end", mb: -2 }}>
          <CloseIcon onClick={onClose} sx={{ zIndex: 3 }} />
        </FlexBox>
        {(isLoading || !conditionalContent) && (
          <FlexBox
            sx={{
              justifyContent: "center",
              mt: "1.5em",
              width: "10em",
            }}
          >
            <CircularProgress color="secondary" size="3em" />
          </FlexBox>
        )}
        {conditionalContent && !isLoading && (
          <Box>
            <Box>{conditionalContent}</Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
