import { Box } from "@mui/material";
import LazyImage from "components/LazyImage";
import { PromotionalBadge } from "modules/promotional/types";
import { FC, memo, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation"; // If you use navigation
import "swiper/css/pagination";

type Props = {
  promotionalBadge?: PromotionalBadge[];
  slidesPerViews?: number;
  onClick: (id?: string) => void;
};

const Component: FC<Props> = ({
  promotionalBadge,
  slidesPerViews = 1.75,
  onClick,
}) => {
  //this is will fix the propgation between nested carousel
  const stopPropagation = useCallback(
    (event: MouseEvent | TouchEvent | PointerEvent) => {
      event.stopPropagation();
    },
    []
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "35px",
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          top: 0,
          right: -10,
          bottom: 0,
          width: "12%",
          background:
            "linear-gradient(to right, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 1) 100%)",
          filter: "blur(5px)",
          zIndex: 1,
        },
      }}
    >
      <Swiper
        spaceBetween={1}
        slidesPerView={slidesPerViews}
        grabCursor={true}
        centeredSlides={false}
        loop={false}
        style={{ width: "100%" }}
        onTouchStart={(_, event) => stopPropagation(event)}
        onTouchMove={(_, event) => stopPropagation(event)}
        onTouchEnd={(_, event) => stopPropagation(event)}
        onClick={(_, event) => stopPropagation(event)}
      >
        {promotionalBadge.map((i) => (
          <SwiperSlide key={i.slug} style={{ padding: 1 }}>
            <Box sx={{ p: 0.5 }}>
              <LazyImage
                src={i?.badge_url || "/assets/images/kachi/kachi-crying.png"}
                alt={i.slug}
                quality={100}
                height={40}
                width={130}
                layout="responsive"
                sx={{ cursor: "pointer", objectFit: "cover" }}
                onClick={() => onClick(i?.id)}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export const PromoBadges = memo(Component);
