import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";

const useResolution = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const isMobile = useMemo(() => {
    return width <= 900 || height <= 900;
  }, [height, width]);

  const isLandscape = useMemo(() => {
    return width > height;
  }, [height, width]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const windowListener = useCallback(
    debounce(() => {
      if (window) {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      }
    }, 250),
    []
  );

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      window.addEventListener("resize", windowListener);
    }
    return () => {
      windowListener.cancel();
      window && window.removeEventListener("resize", windowListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    width,
    height,
    isMobile,
    isLandscape,
  };
};

export default useResolution;
