import { ArrowRight } from "@mui/icons-material";
import { Box } from "@mui/material";
import { colors } from "common";
import Link from "next/link";
import React, { ReactNode, useMemo } from "react";
import { FlexBetween, FlexBox, FlexRow } from "./flex-box";
import { useRouter } from "next/router";
import { navigationRoutes } from "common/constants/navigation-routes";
import { H2 } from "./Typography";

// ===================================================
type CategorySectionHeaderProps = {
  title?: string;
  icon?: ReactNode;
  seeMoreLink?: string;
  titleFontSize?: string;
  seeMoreLinkTxt?: string;
  linkInNewTab?: boolean;
  onClickSeeMore?: () => void;
};
// ===================================================

const CategorySectionHeader: React.FC<CategorySectionHeaderProps> = (props) => {
  const {
    onClickSeeMore,
    title,
    seeMoreLink,
    icon,
    titleFontSize,
    seeMoreLinkTxt,
    linkInNewTab = false,
  } = props;

  const { pathname } = useRouter();

  const isCheckoutPage = useMemo(() => {
    return pathname === navigationRoutes.checkout;
  }, [pathname]);

  return (
    <Box mb={1.5}>
      <FlexBetween>
        <FlexBox alignItems="center" gap={1}>
          {icon && <FlexBox alignItems="center">{icon}</FlexBox>}
          <H2
            fontWeight="bold"
            lineHeight="1"
            fontSize={titleFontSize || undefined}
          >
            {title}
          </H2>
        </FlexBox>

        {onClickSeeMore && (
          <FlexBox
            alignItems="center"
            color={colors.primary}
            onClick={onClickSeeMore}
            sx={{ cursor: "pointer" }}
          >
            {seeMoreLinkTxt || "View all"}
            <ArrowRight fontSize="small" color="inherit" />
          </FlexBox>
        )}

        {seeMoreLink && !isCheckoutPage && (
          <Link href={seeMoreLink}>
            <a
              target={linkInNewTab ? "_blank" : ""}
              rel={linkInNewTab ? "noopener noreferrer" : ""}
            >
              <FlexBox alignItems="center" color={colors.primary}>
                {seeMoreLinkTxt || "View all"}
                <ArrowRight fontSize="small" color="inherit" />
              </FlexBox>
            </a>
          </Link>
        )}
      </FlexBetween>
      {/* <FlexRow mt={1} alignItems="flex-end">
        <Box sx={{ backgroundColor: colors.primary, height: 3, width: 70 }} />
        <Box sx={{ backgroundColor: colors.grey[300], height: 2, flex: 1 }} />
      </FlexRow> */}
    </Box>
  );
};

export default CategorySectionHeader;
